
@font-face {
  font-family: Tinos;
  src: url('../fonts/Tinos-Regular.woff');
}

@font-face {
  font-family: Ballet;
  src: url('../fonts/Ballet-Regular-VariableFont_opsz.woff');
}

@font-face {
  font-family: BeauRivage;
  src: url('../fonts/BeauRivage-Regular.woff');
}

@font-face {
  font-family: CormorantSC;
  src: url('../fonts/CormorantSC-Regular.woff');
}

@font-face {
  font-family: JuliusSansOne;
  src: url('../fonts/JuliusSansOne-Regular.woff');
}

@font-face {
  font-family: MonsieurLaDoulaise;
  src: url('../fonts/MonsieurLaDoulaise-Regular.woff');
}

@font-face {
  font-family: RockSalt;
  src: url('../fonts/RockSalt-Regular.woff');
}

@font-face {
  font-family: RubikMonoOne;
  src: url('../fonts/RubikMonoOne-Regular.woff');
}

@font-face {
  font-family: Schoolbell;
  src: url('../fonts/Schoolbell-Regular.woff');
}

@font-face {
  font-family: Silkscreen;
  src: url('../fonts/Silkscreen-Regular.woff');
}

@font-face {
  font-family: SpecialElite;
  src: url('../fonts/SpecialElite-Regular.woff');
}

.ballet_font{
  font-family: Ballet; 
}

.beaurivage_font{
  font-family: BeauRivage;
}

.cormorantsc_font{
  font-family: CormorantSC;
}

.juliussansone_font{
  font-family: JuliusSansOne;
}

.monsieurladoulaise_font{
  font-family: MonsieurLaDoulaise;
}

.rocksalt_font{
  font-family: RockSalt;
}

.rubikmonoone_font{
  font-family: RubikMonoOne;
}

.schoolbell_font{
  font-family: Schoolbell;
}

.silkscreen_font{
  font-family: Silkscreen;
}

.specialelite_font{
  font-family: SpecialElite;
}

.bodyClass {
  background: url('/images/background.jpeg');
}

.body_font {
  font-family: Tinos;
}

.title_font {
  font-family: Tinos;
}

.sidebarmbox{
  font-family: Tinos;
}

.nobile_font {
  font-family: Tinos;
}

.menu_font {
  font-family: Tinos;
  display: block;
  text-align: center;
}

.loadingContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 56px;
  z-index: 50;
  background: #FFFFFF;
}

.navbarShow{
  display: block;
  position: fixed;
  top: 55px;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  padding-top: 56px;
  z-index: 50;
  background: #FFFFFF;
}

.navbar ul{
  list-style: none;
}

.navbar ul li {
  display: block;
}

.navbar ul li a {
  padding: 6px 8px;
  display: block;
  width: 100%;
  text-align: center;
}

@mixin nav-link {
  padding: 6px 8px;
  display: block;
  width: 100%;
  text-align: center;
}

.navLink {
  @include nav-link;
  color: #000000 !important;
}

.navLinkDim{
  @include nav-link;
  color: #CCCCCC;
  text-decoration: line-through;
  pointer-events: none;
}

.btnMenu {
  position: absolute !important;
  right: 0;
  top: 0;
}

.btnMenuSvg {
  width: 40px;
  height: 40px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
}

.description {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  font-size: 0.85rem;
  max-width: var(--max-width);
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
}

.description a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.description p {
  position: relative;
  margin: 0;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.5);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
}

.code {
  font-weight: 700;
  font-family: var(--font-mono);
}

.btnLogout{
  display: block;
  font-family: Tinos;
}


.btnMobileLogout{
  /* display: none;
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  background-color: #000 !important;
  color: #fff;
  font-family: Agrandir !important; */
  color: #000000 !important;
  width: 100%;
  text-align: center;
}

.artistName{
  font-family: Tinos;
}

.artistDescription{
  font-family: Tinos;
  text-align: left;
  margin-left: 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(25%, auto));
  max-width: 100%;
  width: var(--max-width);
}

.card {
  padding: 1rem 1.2rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.card h2 {
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.card p {
  margin: 0;
  opacity: 0.6;
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 30ch;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
}

.center::before {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 480px;
  height: 360px;
  margin-left: -400px;
}

.center::after {
  background: var(--primary-glow);
  width: 240px;
  height: 180px;
  z-index: -1;
}

.center::before,
.center::after {
  content: '';
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

.logo {
  position: relative;
}

/* Sidebar */

.sidebar {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  height: 7vh;
  width: 100%;
}

.sidebartitle {
  font-size: small;
  color:#bbb;
  font-weight: 200;
  position: relative;
  z-index: 10;
}

.sidebarmain {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.sidebarstep {
  font-size: medium;
  color: #bbb;
  font-weight: 200;
  font-family: Tinos;
}

.sidebarsteplabel{
  display: inline-block;
}

/* Create your design */
.createyourdesign{

}

.createyourdesign_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc( 100vh - 76px );
}

.createyourdesign_buttoncontainer{

}

.createyourdesign_buttoncontainer button{
  color: #000;
  display: block;
  border: 1px solid #000;
  border-radius: 15px;
  min-width: 150px;
  margin-top: 10px;

  &.btnupload{
     
  }
}

/* My Tattoos */
.mytattoos {
  width: 100%;
}

.mytattoosempty{
  /* background: #f2ede1; */
  background: #FFF;
}

.mytryonsempty,
.mytattoosempty {
  padding-bottom: 100px;
}

.mytattoosempty .container {
  height: 100%;
  width: 100%;
  margin-top: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mytattoosempty .line {
  height: 70vh;
  width: 1px;
  background: linear-gradient(to bottom, white, rgb(0, 0, 0));
}

.mytattoosempty .textBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 12%;
  width: 240px;
  /* background-color: rgba(255,255,255,0.5); */
  /* background: #f2ede1; */
  background: #FFF;
  position: absolute;
  z-index: 2;
}

.mytattoosempty .smallText {
  color: #b3b3b3;
  font-size: large;
  padding-bottom: 5px;
  font-weight: 200;
  font-family: Tinos;
}

.mytattoosempty .largeText {
  color: black;
  font-size: x-large;
  font-weight: 500;  
  font-family: Tinos;
}

.mytattoosempty .buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mytattoosempty .button {
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  border: none;
  background-color: black;
  color: white;
  font-size: 16px;
  font-weight: 300;
}

.mytattooscards{
  padding: 20px 20px 0;
}

/* TattooCard */
.tattooCard{
  margin-top: 20px;
}

.tattoo_flash_img{
  display: block;
  width: 100%;
}

/* Flashcard */
.flashcard {
  border: 1px solid #ccc;
  // border-radius: 20px;
  margin-bottom: 10px;
  margin: 10px;
  // width: 300px;
  width: calc(50% - 20px);
  // height: 300px;
  display: inline-flex;
  justify-content: center;
}

.flashcardContainer {
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  // padding-left: 5vw;
  /* padding-top: 10vh; */
  /* padding-left: 15vw; */
  /* padding-right: 5vw; */
}

.flashcardInnerContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: calc(50% - 20px);
}

.flashcard-image-container {
  position: relative;
  align-items: center;
}

.flashcard .try-on {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #fff;
  padding: 5px;
  border-radius: 4px;
}

.flashcard .info-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.flashcard .info-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  color: #fff;
}

.flashcard .artist-info {
  align-content: center;
  margin-bottom: 20px;
}

.flashcard .artist-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.flashcard .artist-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.flashcard .artist-photo {
  margin-bottom: 10px;
}

.flashcard .info-popup a {
  color: #fff;
  text-decoration: underline;
}

.flashcardImageContainer {
  position: relative;
}

.tryOns{
  padding: 20px;
}

.tryOn {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}

.tryOnItem{
  margin-top: 20px;
}

.tryOnImage{
  display: block;
  width: 100%;
}

.postSaveButtonControl{
  
  .horizontalButtonContainer{
    display: flex;
    justify-content: center;
  }
}

.buttonContainer {
  position: absolute;
  bottom: 0;
  left: 10px;
  background: #FFFFFF;
  width: calc(100% - 20px);
  z-index: 4;
}

.infoButton,
.tryonButton {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #fff;
  border:#fff;
  color: #fff;
  cursor: pointer;
}

.buttonContainer .infoButton{
  position: absolute;
  right: 0;
}

.tryonButtonText{
  position: relative;
  top: -3px;
  left: 5px;
}

.infoButton p,
.tryonButton p {
  color: #aaa;
}

.infoPanel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.panelBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.panelContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
}

.artistPhoto{
  width: 100%;
}

.artistPhoto img{
  display: 'block';
  width: 100%;
  height: auto;
}

/* Flash Gallery */
.flashgallery {
  width: 100%;
  height: auto;
  justify-content: center;
  align-content: center;
  padding-bottom: 100px;
}

.modalcontrol{
  padding: 10px 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-left: 0;
  background: rgba(255,255,255,0.4);
  z-index: 10;
}

.modalInnerControl{
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin new-btn-baseline-style {
  color: #000;
  display: block;
  border: 1px solid #000;
  border-radius: 15px;
}

@mixin btn-style {
  border-radius: 10px !important;
  border: 2px solid #000000 !important;
  position: relative;
  padding: 5px 10px !important;
  width: 180px;
  // transition: all 1s;
  
  .btnInnerStyle {
    display: block;
    background: rgba(0,0,0,0.7);
    padding: 5px 10px;
    transition: all 1s;
    width: 100%;

    .btnImage {
      display: inline-block;
      position: relative;
      top: 4px;
    }
  }

  // &:hover{
  //   padding: 5px 0 !important;
  //   .btnInnerStyle{
  //     padding: 5px 20px;
  //   }
  // }
}

@mixin btn-style-dim {
  @include btn-style;
  
  .btnInnerStyle{
    background: rgba(0,0,0,0.2);
    text-decoration: line-through;
  }
}

.btnStyle {
  @include btn-style;
}

.btnStyleDim {
  @include btn-style-dim;
}

.btnCreateDesign{
  width: 250px;
  text-align: center;
}

.fileInput {
  position: absolute;
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
  opacity: 0;
}


/* Image Cropper */
.imageCropper{
  
}

/* Generate Tattoo */
.generateTattooDialogActions .btnGenerate{
  @include btn-style;
  svg {
    position: absolute;
    right: 20px;
  }
}

.generateTattooDialogActions .btnGenerateDim{
  @include btn-style-dim;
  svg {
    position: absolute;
    right: 20px;
  }
}


/* Select Tattoo */
.selectTattooDialogActions{
  padding: 10;
}

.selectTattooDialogActions .btnRetry{
  position: absolute;
  left: 0;
  right: auto;
}

/* Select Tattoo */
.btnUploadBody {
  @include btn-style;
}


.selectTattooDialogActions{

}

.selectTattooDialogActions .btnRetry{
  @include btn-style;

  svg {
    position: absolute;
    left: 20px;
  }
}

.selectTattooDialogActions .btnSave{
  @include btn-style;
  margin-left: 0;
  margin-top: 10px;
  img {
    position: absolute;
    left: 20px;
  }
}

.selectTattooDialogActions .btnSaveDim{
  @include btn-style-dim;
  margin-left: 0;
  margin-top: 10px;
  img {
    position: absolute;
    left: 20px;
  }
}

.selectTattooDialogActions .btnTryOn{
  @include btn-style;
  // margin-left: 0;
  // margin-top: 10px;
  .btnInnerStyle{
    padding-right: 30px;
  }

  svg {
    position: absolute;
    right: 20px;
  }
}

.selectTattooDialogActions .btnTryOnDim{
  @include btn-style-dim;
  // margin-left: 0;
  // margin-top: 10px;
  .btnInnerStyle{
    padding-right: 30px;
  }

  svg {
    position: absolute;
    right: 20px;
  }
}

/* Try-on Select */
.tryOnSelectDislogActions{
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.tryOnSelectDislogActions .btnTryOn{
  @include btn-style;
  .btnInnerStyle{
    padding-right: 30px;
  }

  svg {
    position: absolute;
    right: 20px;
  }

  border-color: #297302 !important;

  .btnInnerStyle{
    background: #297302 !important;
  }
}

/* Tattoo Fitting */
.tattooFittingDialogActions {

}

.tattooFittingDialogActions .btnCancel {
  @include btn-style;
}

.tattooFittingDialogActions .btnTryOn{
  @include btn-style;
  border-color: #297302 !important;

  .btnInnerStyle{
    background: #297302 !important;
  }
}

.tattooFittingDialogActions .btnSave {
  @include btn-style;
}

/* Tattoo Editor */
.textEditorWrapper{
  border: 1px solid rgba(60,60,67,0.6);
  border-radius:15px;
  padding: 20px;
}

.textEditor {
  display: block;
  width: 100%;
  height: 100px;
  font-size: 28px;
  text-align: center;
  border: none;

  &[data-theme="dark"]{
    --background-color: #fff;
    --text-color: #000;
    color: #000000;
  }
}

.fontSelectorContainer {
  display: block;
  // padding-left: 16px;
  // padding-right: 16px;
  // padding-left: 5vw;
  /* padding-top: 10vh; */
  /* padding-left: 15vw; */
  /* padding-right: 5vw; */
}

.fontSelectorInnerContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: calc(50% - 10px);

  button{
    @include new-btn-baseline-style;
    display: inline-flex;
    width: calc(50% - 10px);
    margin-top: 20px;
    font-size: 20px;

    &:nth-child(even){
      margin-left: 20px;
    }

    &.selected{
      background: #CCC;
    }
  }
}

.btnGuideButton {
  
}

/* Text Editor End */


/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

@media (prefers-reduced-motion) {
  .card:hover span {
    transform: none;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .content {
    padding: 4rem;
  }

  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: 320px;
    text-align: center;
  }

  .card {
    padding: 1rem 2.5rem;
  }

  .card h2 {
    margin-bottom: 0.5rem;
  }

  .center {
    padding: 8rem 0 6rem;
  }

  .center::before {
    transform: none;
    height: 300px;
  }

  .description {
    font-size: 0.8rem;
  }

  .description a {
    padding: 1rem;
  }

  .description p,
  .description div {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .description p {
    align-items: center;
    inset: 0 0 auto;
    padding: 2rem 1rem 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
    background: linear-gradient(
      to bottom,
      rgba(var(--background-start-rgb), 1),
      rgba(var(--callout-rgb), 0.5)
    );
    background-clip: padding-box;
    backdrop-filter: blur(24px);
  }

  .description div {
    align-items: flex-end;
    pointer-events: none;
    inset: auto 0 0;
    padding: 2rem;
    height: 200px;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(var(--background-end-rgb)) 40%
    );
    z-index: 1;
  }
}

@media (max-width: 960px){
  .sidebarsteplabel{
    display: none;
  }

  .btnLogout{
    display: none !important;
  }

  .btnMobileLogout{
    display: block;
  }

  // .selectTattooDialogActions{
  //   flex-direction: column;
  // }
  
  .selectTattooDialogActions .btnRetry{
    position: relative;
  }
  
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
}

@media (prefers-color-scheme: dark) {
  .vercelLogo {
    filter: invert(1);
  }

  .logo {
    filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media screen and (orientation: landscape) {

}